<template>
  <section>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filter" size="mini">
        <el-form-item>
          <el-input
            v-model.trim="filter.mn"
            placeholder="编码"
            @keyup.native.enter.stop="getData"
          >
            <el-button
              slot="prepend"
              icon="el-icon-refresh"
              @click="handleRefresh"
            ></el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            placeholder="设备状态"
            v-model="filter.status"
            @change="getData"
          >
            <el-option
              v-for="item in statusOps"
              :key="item.val"
              :label="item.label"
              :value="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-cascader
            v-model="filter.owner"
            @change="getData"
            placeholder="所属单位"
            :options="customerTree"
            :props="orgProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="filter.peorid"
            type="daterange"
            value-format="timestamp"
            @change="getData"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-download"
            @click="openExportSizeInput"
            :loading="exportLoading"
            >导出</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="showMN">显示设备编号</el-checkbox>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist.content"
        @sort-change="handleSort"
        show-summary
        size="mini"
        border
        highlight-current-row
        v-loading="loading"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column type="index" label="序号" align="center" width="55" />
        <el-table-column
          prop="Name"
          label="监测点"
          width="120"
          show-overflow-tooltip
          header-align="center"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click="handlePdfRecord(row)">{{
              row.Name
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showMN"
          label="风机设备编号"
          show-overflow-tooltip
          align="center"
          width="90"
          header-align="center"
        />
        <el-table-column
          prop="FanCurrent"
          label="风机电流值"
          align="center"
          width="118"
          header-align="center"
        />
        <el-table-column
          prop="FanStatus"
          sortable="custom"
          label="风机状态"
          show-overflow-tooltip
          width="100"
          align="center"
          header-align="center"
        />
        <el-table-column
          label="净化器设备编号"
          width="130"
          show-overflow-tooltip
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="PurCurrent"
          label="净化器电流值"
          width="130"
          align="center"
          header-align="center"
        />
        <el-table-column
          label="净化器状态"
          width="140"
          align="center"
          header-align="center"
        />
        <el-table-column
          label="联动比"
          width="140"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="FaultNum2"
          sortable="custom"
          label="端口2故障次数"
          width="128"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="FaultPeriod2"
          :formatter="hourFormatter"
          sortable="custom"
          label="端口2故障时间合计(小时)"
          width="140"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="NormalPeriod2"
          :formatter="hourFormatter"
          sortable="custom"
          label="端口2正常时间合计(小时)"
          width="140"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="FaultNum3"
          sortable="custom"
          label="端口3故障次数"
          width="128"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="FaultPeriod3"
          :formatter="hourFormatter"
          sortable="custom"
          label="端口3故障时间合计(小时)"
          width="140"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="NormalPeriod3"
          :formatter="hourFormatter"
          sortable="custom"
          label="端口3正常时间合计(小时)"
          width="140"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="FaultNum4"
          sortable="custom"
          label="端口4故障次数"
          width="128"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="FaultPeriod4"
          :formatter="hourFormatter"
          sortable="custom"
          label="端口4故障时间合计(小时)"
          width="140"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="NormalPeriod4"
          :formatter="hourFormatter"
          sortable="custom"
          label="端口4正常时间合计(小时)"
          width="140"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Status"
          sortable="custom"
          label="设备状态"
          width="70"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <img height="40" width="40" :src="getImg(scope.row.Status)" />
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          header-align="center"
          width="140"
          fixed="right"
        >
          <template slot-scope="scope">
            <i
              title="一点一档"
              @click="handlePdfRecordList(scope.row)"
              class="opt el-icon-document"
            />
            <i
              title="清除异常记录"
              v-if="role === 'sa'"
              @click="handleDelRecord(scope.row.MN)"
              class="opt el-icon-delete-solid"
            />
            <i
              title="异常离线处理"
              @click="handleAlarmRecord(scope.row.LocaleId, 2, scope.row.Owner)"
              class="opt fa fa-exclamation-triangle"
            />
            <i
              title="故障处理"
              @click="handleAlarmRecord(scope.row.LocaleId, 1, scope.row.Owner)"
              class="opt fa fa-bell"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span="24" class="toolbar">
      <el-pagination
        small
        background
        @size-change="(v) => handlePageChange(v, 'size')"
        @current-change="(v) => handlePageChange(v, 'page')"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible"
      @close="handlerClose"
    >
      <span slot="title"
        ><strong>{{ owner }}</strong></span
      >
      <el-col :span="24">
        <el-table
          :data="dataRecord.content"
          size="mini"
          border
          highlight-current-row
          v-loading="loading"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="序号"
            align="center"
            width="55"
          />
          <el-table-column
            prop="AcquitAt"
            :formatter="dateFormat"
            label="异常日期"
            align="center"
            header-align="center"
          />
          <el-table-column
            prop="ProcessAt"
            :formatter="dateFormat"
            label="处理日期"
            align="center"
            header-align="center"
          />
          <el-table-column
            prop="Processor"
            :formatter="userFormatter"
            label="处理人"
            align="center"
            header-align="center"
          />
          <el-table-column
            prop="Solution"
            label="原因及处理结果"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <el-input
                v-if="scope.row.editable"
                class="input-box"
                size="mini"
                v-model="scope.row.Solution"
              />
              <span v-else>{{ scope.row.Solution }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop='Processor' :formatter="userFormatter" label='维护类型' align='center' header-align='center' /> -->
          <el-table-column
            prop="AlarmRecordPicS"
            label="现场图片"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <div class="preview_image_box">
                <el-button type="text" size="mini">查看</el-button>
                <el-image
                  :src="scope.row.AlarmRecordPicS[0]"
                  :preview-src-list="scope.row.AlarmRecordPicS"
                >
                <div slot="error" class="image-slot">
                  暂无图片
                </div>
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="ProcessingAddress"
            label="处理地点"
            align="center"
            header-align="center"
          />
          <!-- <el-table-column label='操作' align='center' header-align='center' width='80'>
            <template slot-scope='scope'>
              <i @click='handleEdit(scope.$index,scope.row)' :class="scope.row.editable?'opt fa fa-floppy-o':'opt el-icon-edit'" />
            </template>
          </el-table-column> -->
        </el-table>
      </el-col>
      <!-- 工具条 -->
      <el-col :span="24" class="toolbar">
        <el-pagination
          small
          background
          @size-change="(v) => handlePageChange(v, 'alarmSize')"
          @current-change="(v) => handlePageChange(v, 'alarmPage')"
          :current-page.sync="filter.page"
          :page-sizes="filter.pageSizes"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dataRecord.total"
          style="display: inline-block; margin-left: 15px"
        >
        </el-pagination>
      </el-col>
    </el-dialog>
    <!-- 生成过的一点一档记录 -->
    <el-dialog
      title="一点一档记录"
      :visible.sync="pdfRecordVisible"
      width="800px"
    >
      <el-table
        :data="pdfList"
        size="mini"
        border
        highlight-current-row
        :max-height="clientHeight - 40"
        style="width: 100%"
      >
        <el-table-column
          prop="BeginDate"
          label="开始时间"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="EndDate"
          label="结束时间"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="CreatedAt"
          label="生成时间"
          align="center"
          header-align="center"
        />
        <el-table-column label="操作" align="center" header-align="center">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="handleLook(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click.native="pdfRecordVisible = false"
          >关闭</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="isShowNotificationDialog"
    >
      <notification7Dialog
        :showData="isShowNotificationDialogData"
        :lineData="tenMinOption"
      ></notification7Dialog>
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from "vuex";
import _1 from "@/asset/img/1.png";
import _2 from "@/asset/img/2.png";
import _3 from "@/asset/img/3.png";
import _4 from "@/asset/img/4.png";
import {
  join,
  dateFormater,
  stg,
  export_json,
  getUserInfo,
  underline,
  getPname,
  dataFormatter,
  base64ToBlob,
} from "@/util/index";
import notification7Dialog from "@/component/Notification7Dialog";

export default {
  components: { notification7Dialog },
  data() {
    return {
      url:
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
      exportLoading: false,
      loading: false,
      datalist: {},
      dataRecord: {},
      mn: null,
      owner: null,
      role: null,
      deviceInfo: {},
      userInfo: {},
      pdfRecordVisible: false,
      pdfList: [],
      dlg: {
        visible: false,
        type: null,
        title: "",
      },
      filter: {
        status: null,
        peorid: null,
        by: "status",
        mode: "asc",
        alarmPage: 1,
        page: 1,
        alarmSize: 20,
        size: 20,
        pageSizes: [20, 30, 50],
      },
      isShowNotificationDialog: false,
      isShowNotificationDialogData: {
        markLine: {
          symbol: "none",
          itemStyle: {
            normal: {
              lineStyle: {
                type: "solid",
                // color: "red",
              },
              label: {
                // show: false,
                position: "start",
                formatter: "{c} ",
              },
            },
          },
          data: [],
        },
        dataOption: {
          columns: ["采集时间", "油烟折算浓度"],
          rows: [],
        },
      },
      tenMinOption: {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["油烟浓度", "颗粒物浓度", "非甲烷总烃浓度"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [],
          boundaryGap: false,
          axisLabel: {
            interval: 143,
          },
        },
        yAxis: {
          type: "value",
          // min: 5,
          // data : [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4,5],
          name: "平均浓度mg/m³",
        },
        series: [
          {
            name: "油烟浓度",
            type: "line",
            data: [],
            itemStyle: {
              normal: {
                color: "#00A1FF",
                lineStyle: {
                  type: "solid",
                  color: "#00A1FF",
                },
              },
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#00A1FF",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  yAxis: 1,
                },
              ],
            },
          },
          {
            name: "颗粒物浓度",
            type: "line",
            data: [],
            itemStyle: {
              normal: {
                color: "#F5B049",
                lineStyle: {
                  type: "solid",
                  color: "#F5B049",
                },
              },
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#F5B049",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  yAxis: 1,
                },
              ],
            },
          },
          {
            name: "非甲烷总烃浓度",
            type: "line",
            data: [],
            itemStyle: {
              normal: {
                color: "#009999",
                lineStyle: {
                  type: "solid",
                  color: "#009999",
                },
              },
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#009999",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  yAxis: 1,
                },
              ],
            },
          },
        ],
      },
      showMN: false,
      statusOps: Object.freeze([
        { val: 1, label: "达标" },
        { val: 2, label: "超标" },
        { val: 3, label: "正常离线"},
        { val: 4, label: "异常离线" },
      ]),
    };
  },
  computed: {
    ...mapState(["clientHeight", "customerTree", "user"]),
    ...mapState({
      orgProps: (state) =>
        Object.assign({}, state.props, { label: "Org", checkStrictly: true }),
    }),
  },
  created() {
    this.userInfo = getUserInfo();
    this.role = stg().getItem("role");
    this.getData();
  },
  methods: {
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.datalist.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({ value }) => {
        this.handleDownload(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    getData: function () {
      this.loading = true;
      var para = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: this.filter.size,
        Typ: 2,
        SortBy: this.filter.by,
        SortMode: this.filter.mode,
      };
      para.Param = {};
      if (this.filter.status) {
        para.Param["Status"] = this.filter.status;
      }
      if (this.filter.mn) {
        para.Param["mn"] = this.filter.mn;
      }
      if (this.filter.owner && this.filter.owner.length !== 0) {
        para.Param["Owner"] = join(this.filter.owner);
      }
      if (!this.filter.peorid) {
        this.filter.peorid = [];
        const now = new Date();
        now.setDate(now.getDate() - 1);
        now.setHours(0, 0, 0, 0);
        this.filter.peorid[1] = now.getTime();
        now.setDate(now.getDate() - 30);
        this.filter.peorid[0] = now.getTime();
      }
      para.Param["StartAt"] = this.filter.peorid[0] / 1000;
      para.Param["EndAt"] = this.filter.peorid[1] / 1000;
      this.$post("admin/listCurAlarm", para).then((res) => {
        this.datalist = {
          ...res,
          FanStatus: res.content.FanCurrent >= 0 ? 1 : 0
        };
        this.loading = false;
      });
    },
    handleDownload(exportSize) {
      this.exportLoading = true
      var para = {
        StartAt: 0,
        Size: exportSize,
        Typ: 2,
        SortBy: this.filter.by,
        SortMode: this.filter.mode,
      };
      para.Param = {
        IsDownload: true
      };
      if (this.filter.status) {
        para.Param["Status"] = this.filter.status;
      }
      if (this.filter.mn) {
        para.Param["mn"] = this.filter.mn;
      }
      if (this.filter.owner && this.filter.owner.length !== 0) {
        para.Param["Owner"] = join(this.filter.owner);
      }
      if (!this.filter.peorid) {
        this.filter.peorid = [];
        const now = new Date();
        now.setDate(now.getDate() - 1);
        now.setHours(0, 0, 0, 0);
        this.filter.peorid[1] = now.getTime();
        now.setDate(now.getDate() - 30);
        this.filter.peorid[0] = now.getTime();
      }
      para.Param["StartAt"] = this.filter.peorid[0] / 1000;
      para.Param["EndAt"] = this.filter.peorid[1] / 1000;
      this.$post("admin/listCurAlarm", para).then((res) => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
        this.exportLoading = false
      });
    },
    hourFormatter(r, c) {
      return (r[c.property] / 60).toFixed(2);
    },
    dateFormat(r, c) {
      return dateFormater(r[c.property]);
    },
    doneFormatter(r, c, v) {
      return r.DownNum - v;
    },
    userFormatter(r, c) {
      return dataFormatter(r[c.property], this.user);
    },
    customerFormatter(r, c) {
      // return getPname(this.customerTree, r[c.property], 'Org').join('/')
    },
    faultFormatter(r, c, v) {
      return v;
    },
    downFormatter(r, c, v) {
      return v;
    },
    getImg(status) {
      switch (status) {
        case 1:
          return _1;
        case 2:
          return _2;
        case 3:
          return _3;
        case 4:
          return _4;
      }
    },
    getAlarmRecord() {
      var para = {
        StartAt: (this.filter.alarmPage - 1) * this.filter.alarmSize,
        Size: this.filter.alarmSize,
        Typ: this.typ,
        Param: { LocaleId: this.LocaleId },
      };
      this.$post("admin/listAlarmRecord", para)
        .then((res) => {
          this.dataRecord = res;
        })
        .catch(() => {});
    },
    // 一点一档--历史记录
    handlePdfRecordList(row) {
      // this.localeId = row.LocaleId;
      this.pdfRecordVisible = true;
      this.pdfList = [];
      this.$post("admin/queryLocaleArchive", { LocaleId: row.LocaleId })
        .then((res) => {
          this.pdfList = res;
        })
        .catch(() => {});
    },
    // 查看历史一点一档
    handleLook(row) {
      this.download(row.LocaleId, row.Archive);
    },
    //下载图片
    download(name, imgData) {
      this.downloadFile(name, imgData);
    },
    //下载
    downloadFile(fileName, content) {
      let aLink = document.createElement("a");
      let blob = base64ToBlob(content); //new Blob([content]);
      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName;
      aLink.href = URL.createObjectURL(blob);
      // aLink.dispatchEvent(evt);
      // aLink.click()
      aLink.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      ); //兼容火狐
    },
    handleOption() {
      this.filter.page = 1;
      this.filter.by = "Status";
      this.filter.mode = "asc";
      this.getData();
    },
    handleSort(c) {
      if (c.order) {
        this.filter.by = underline(c.prop);
        this.filter.mode = c.order.replace(/ending/g, "");
      }
      this.getData();
    },
    handleAlarmRecord(id, typ, owner) {
      this.LocaleId = id;
      this.typ = typ;
      this.filter.alarmPage = 1;
      this.owner = getPname(this.customerTree, owner, "Org").join("/");
      this.getAlarmRecord();
      this.dlg.visible = true;
    },
    handleEdit(i, row) {
      if (row.Solution === "" || row.editable) {
        if (row.editable) {
          this.changeState = true;
          this.$post("admin/alarmProcess", {
            id: row.Id,
            solution: row.Solution,
          });
        }
        this.$set(
          this.dataRecord.content,
          i,
          Object.assign(row, {
            editable: !row.editable,
            ProcessAt: Date.parse(new Date()) / 1000,
            Processor: this.userInfo.Name,
          })
        );
      }
    },
    getDeviceInfo(id) {
      this.$get("admin/getDevice", { id })
        .then((res) => {
          this.deviceInfo = res;
        })
        .catch(() => {});
    },
    handleDelRecord(mn) {
      this.$confirm("确定清空所有报警吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          this.$get("admin/delAlarmRecord", { mn })
            .then(() => {
              this.handleRefresh();
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    handleRefresh() {
      this.filter.page = 1;
      this.filter.status = null;
      this.filter.mn = null;
      this.filter.owner = null;
      this.getData();
    },
    handlePageChange(val, field) {
      this.filter[field] = val;
      if (field.indexOf("alarm") > 0) {
        this.getAlarmRecord();
      } else {
        this.getData();
      }
    },
    handlerClose: function () {
      if (this.changeState) {
        this.changeState = false;
        this.handleRefresh();
      }
      this.device = [];
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
    },
    handlePdfRecord(data) {
      // console.log(data)
      let now = new Date();
      now = now.getTime();
      let last = now - 30 * 24 * 3600 * 1000;
      const par = {
        LocaleId: data.LocaleId,
        AcquitAtBegin: parseInt(last / 1000),
        AcquitAtEnd: parseInt(now / 1000),
      };
      const list = [];
      let customForm = {};
      this.$post("admin/listDataDetectorDailySumDaysByLocaleId", par)
        .then((res) => {
          list.push(res);
          customForm = { ...res.customer };
          this.$post("admin/listTenMinDataLastDays", {
            mn: data.MN,
            // mn: '010000A8900016F019060199',
            LastDays: 7,
          }).then((response) => {
            // console.log(response)
            this.tenMinOption.series[0]["data"] = [];
            this.tenMinOption.series[0]["markLine"]["data"][0]["yAxis"] =
              data.EmissionsSill || 0;
            this.tenMinOption.xAxis["data"] = [];
            this.tenMinOption.series[1]["data"] = [];
            this.tenMinOption.series[1]["markLine"]["data"][0]["yAxis"] =
              data.GranuleSill || 0;
            this.tenMinOption.series[2]["data"] = [];
            this.tenMinOption.series[2]["markLine"]["data"][0]["yAxis"] =
              data.HydrocarbonSill || 0;
            if (response && response.length !== 0) {
              const endTime = response[0]["acquit_at"] + 143 * 7;
              for (var i = response[0]["acquit_at"]; i <= endTime; i++) {
                let obj = {
                  emissions_conc: 0,
                  granule_conc: 0,
                  hydrocarbon_conc: 0,
                  counter: 1,
                };
                response.forEach((e) => {
                  if (e.acquit_at === i) {
                    obj = e;
                  }
                });
                this.tenMinOption.series[0]["data"].push(
                  (obj.emissions_conc / obj.counter).toFixed(2)
                );
                this.tenMinOption.series[1]["data"].push(
                  (obj.granule_conc / obj.counter).toFixed(2)
                );
                this.tenMinOption.series[2]["data"].push(
                  (obj.hydrocarbon_conc / obj.counter).toFixed(2)
                );
                this.tenMinOption.xAxis["data"].push(
                  dateFormater(i * 600, true, false)
                );
              }
              this.$forceUpdate();
            }
          });
        })
        .then((response) => {
          // const tpl = { mn: '010000A8900016F018010029' };
          const tpl = { localeId: data.LocaleId };
          tpl["AcquitAtBegin"] = parseInt(last / 1000);
          tpl["AcquitAtEnd"] = parseInt(now / 1000);
          this.$post("admin/queryDataDetectorDaily", tpl).then((res) => {
            this.isShowNotificationDialogData = Object.assign(
              {},
              this.isShowNotificationDialogData,
              customForm
            );
            this.isShowNotificationDialogData.LocaleId = tpl.localeId;
            this.isShowNotificationDialogData.beginDate = dateFormater(
              tpl["AcquitAtBegin"],
              false
            );
            this.isShowNotificationDialogData.endDate = dateFormater(
              tpl["AcquitAtEnd"],
              false
            );
            let data;
            this.isShowNotificationDialogData.dataOption.rows = [];
            for (
              var i = tpl["AcquitAtBegin"];
              i <= tpl["AcquitAtEnd"];
              i += 86400
            ) {
              data = res.find(
                (e) =>
                  dateFormater(e.AcquitAt, false) === dateFormater(i, false)
              );
              this.isShowNotificationDialogData.dataOption.rows.push({
                采集时间: dateFormater(i, false),
                油烟折算浓度: data ? data.CEmissions : 0,
                颗粒物折算浓度: data ? data.CGranule : 0,
                非甲烷总烃折算浓度: data ? data.CHydrocarbon : 0,
              });
            }
            this.isShowNotificationDialog = true;
            this.isShowNotificationDialogData.tableData = list;
            if (res.length) {
              this.isShowNotificationDialogData.markLine.data.push(
              {
                lineStyle: {
                  color: "#00A1FF",
                },
                yAxis: res[0].LocaleEmissionsSill || 0,
              },
              {
                lineStyle: {
                  color: "#F5B049",
                },
                yAxis: res[0].LocaleGranuleSill || 0,
              },
              {
                lineStyle: {
                  color: "#009999",
                },
                yAxis: res[0].LocaleHydrocarbonSill || 0,
              }
            );
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.fa-exclamation-triangle {
  color: #ebb460;
}
.fa-bell {
  color: #f56c6c;
}
.fa-info-circle {
  color: #a0cfff;
}
// 查看图片
.preview_image_box {
  position: relative;
  // z-index: 1;
  .el-button {
    position: relative;
    // z-index: 2;
  }
  .el-image {
    position: absolute;
    // z-index: 10;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  .el-image__inner {
    opacity: 0;
  }
}
</style>
